import { View, Text, TouchableWithoutFeedback, Pressable, Switch, StyleSheet, Alert, Animated } from "react-native";
import { globalStyles } from "../../shared/styles";
import { ContactAvatarWithStatus } from "../contact-avatar-with-status";
import { ImageAspectFit } from "../image";
import { ViewSlide } from "../view-slide";
import { Contact } from '../../shared/interfaces';
import { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useStore, useDispatch } from 'react-redux';
import { RootState, store } from "../../store";
import { http } from "../../services/http-service";
import { setContacts, setSubscribed } from "../../store/agenda/agenda.actions";
import { setIsLoading, setTelegramBotLink } from "../../store/ui/ui.actions";
import { useNavigation, StackActions } from '@react-navigation/native';
import { useFirebase } from "../../services/firebase-service";
import { setFirebaseTokenSentToServer, setSubscription } from "../../store/auth/auth.actions";
import { LastSeen } from "./LastSeen";
import { useTranslation } from "react-i18next";
import { isApp } from "../../shared";
import { APP } from "../../shared/constants";

declare var alert: any, window: any, Notification: any

export const ContactCard = ({ contact }: { contact: Contact }) => {

    const navigation = useNavigation()
    const agendaState = useSelector((state: RootState) => state.agenda)
    const authState = useSelector((state: RootState) => state.auth)
    const [fadeAnim] = useState(new Animated.Value(0));
    const dispatch = useDispatch()
    const firebase = useFirebase()
    const { t } = useTranslation()

    useEffect(() => {
        let isFirstLoaded = store.getState().ui.isFirstLoaded
        //if (!isFirstLoaded) fadeAnim.setValue(1);
        //else {
        setTimeout(() => {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 240,
                useNativeDriver: false
            }).start()
        }, 120)
        //}
    }, [])

    const removeContact = useCallback(async (contact: Contact) => {
        let _contacts = [...agendaState.subscribed]
        let index = _contacts.findIndex(c => c.phone == contact.phone)
        if (index == -1) return;
        let subscribedNumbers = _contacts.map(c => c.phone)
        subscribedNumbers.splice(index, 1);
        //store.dispatch(setIsLoading(true))
        try {
            await http.post("number/sync-subscribed", { numbers: subscribedNumbers })
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 320,
                useNativeDriver: false
            }).start(() => {
                _contacts.splice(index, 1);
                store.dispatch(setSubscribed(_contacts))
            });
        } catch (e) {

        } finally {
            store.dispatch(setIsLoading(false))
        }
    }, [agendaState.subscribed])

    const howToTrack = useCallback(() => {
        Alert.alert(`Can't track contact? :(`, `
    - You can only track contacts that you can see online in Whatsapp.

    - To see them online, you need to receive a message from that contact.

    - If you can see them online on WA, but not here, please unlink, and then link your Whatsapp account again.`, [
            { text: 'OK', onPress: () => console.log('OK Pressed') },
        ]);
    }, [])

    const colorForStatus = (contact: Contact) => {
        let status = contact.status, isOnline = contact.isOnline, color = null
        if (status == 'NUMBER_OK') {
            if (isOnline) {
                color = '#4bbf73'
            } else {
                color = '#909498'
            }
        } else if (status == 'WARNING') {
            color = '#d84315'
        } else if (status == 'PENDING') {
            color = 'lightgray'
        }
        return color
    }

    const toggleContactCollapse = useCallback(async (contact: Contact) => {
        let newContacts = []
        let newCollapse = !contact.isCollapsed
        agendaState.subscribed.forEach(c => {
            let _contact = { ...c }
            _contact.isCollapsed = c.id == contact.id ? newCollapse : true
            newContacts.push(_contact)
        })
        store.dispatch(setSubscribed(newContacts))
    }, [agendaState.subscribed])

    const setPushForNumber = async (checked: boolean) => {
        let _contacts = [...agendaState.subscribed]
        let index = _contacts.findIndex(c => c.phone == contact.phone)
        if (index == -1) return;
        const _contact = { ..._contacts[index] }

        _contact.notify = !!checked ? true : false
        _contacts[index] = _contact
        store.dispatch(setSubscribed(_contacts))
        const pl = { notify: _contacts[index].notify, number: _contacts[index] };//??pq se llama number
        try {
            await http.post("number/notify", pl)
        } catch (e) {
            _contact.notify = !_contact.notify ? true : false
            _contacts[index] = _contact
            store.dispatch(setSubscribed(_contacts))
            console.log(e)
        }
    }

    const getTelegramBotLink = async () => {
        console.log("getTelegramBotLink")
        dispatch(setTelegramBotLink("loading"))
        http.get("user/getTelegramLink")
            .then((result: any) => { // RESULT
                //this.telegramBotLinkLoading = false;
                dispatch(setSubscription(result.subscription))
                setTimeout(() => {
                    dispatch(setTelegramBotLink(result.data.link))
                }, 10)
            })
            .catch((e) => { // ERROR
                console.log(e);
                //this.telegramBotLinkLoading = false;
                dispatch(setTelegramBotLink(null))
                alert(e.error.message || "Unknown error, please try again.")
            });

    }

    const changeNotifyNumber = useCallback(async () => {
        let checked = !contact.notify
        if (!checked) {
            setPushForNumber(false);
            return
        }
        if (await firebase.isSupported() && "Notification" in window && (!firebase.hasPermission() || authState.wasFirebaseTokenSentToServer == false)) {

            // NAVEGADOR SOPORTA NOTIFICACIONES PERO NO ESTÁN ACTIVADAS O NO SE GUARDÓ EL TOKEN EN EL SERVER
            firebase.getToken().then(async (currentToken: string) => {
                if (currentToken) {
                    try {
                        setPushForNumber(true);
                        firebase.sendFirebaseToken(currentToken)
                            .then(() => dispatch(setFirebaseTokenSentToServer(true)))
                    } catch (e) {
                        console.log(e);
                        setPushForNumber(false);
                        alert("Error enabling notifications. Please try again.")
                        dispatch(setFirebaseTokenSentToServer(false))
                    }
                } else {
                    // Show permission request.
                    console.log('No Instance ID token available. Request permission to generate one.');
                    // Show permission UI.
                    alert("Can't enable notifications because you denied the permission. Please remove Chatwatch.net from notifications Block list.")
                    dispatch(setFirebaseTokenSentToServer(false))
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                //this.error = "Can't enable push notifications.";
                if (checked && Notification.permission == "denied") {
                    alert("To activate push notifications you must first grant notification permissions")
                }
                dispatch(setFirebaseTokenSentToServer(false))
            });
        } else if (!authState.subscription.telegram && !(await firebase.isSupported()) && checked) {
            getTelegramBotLink();
        } else {
            // EVERYTHING IS FINE
            setPushForNumber(checked);
        }

    }, [agendaState.subscribed, firebase, setContacts])

    return (
        <Animated.View style={[globalStyles.contactCard, { borderColor: colorForStatus(contact), opacity: fadeAnim }]} key={contact.id}>
            <View style={{ flexDirection: 'row', alignItems: 'center', width: "100%", paddingBottom: 10, paddingTop: 18, paddingHorizontal: 12 }}>
                <ContactAvatarWithStatus contact={contact} />
                <View style={styles.contactInfoSection}>
                    <Text style={styles.contactInfoName}>{contact.name}</Text>
                    <Text style={styles.contactInfoNumber}>+{contact.phone}</Text>
                    {['WARNING', 'BAD_NUMBER'].includes(contact.status) &&
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flexGrow: 1 }}>
                                <Text style={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 14, color: '#d84315', fontStyle: 'italic' }}>{t('main.contactCard.notTracking')}</Text>
                                <TouchableWithoutFeedback onPress={() => howToTrack()}>
                                    <Text style={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 14, color: '#d84315', textDecorationLine: 'underline', fontWeight: '800' }}>{t('main.contactCard.howToTrack')}</Text>
                                </TouchableWithoutFeedback>
                            </View>
                            <Pressable onPress={() => removeContact(contact)}>
                                <ImageAspectFit asset={require('../../../assets/images/ant-design_delete-outlined.png')} style={{ width: 30, height: 30 }} imageStyle={{ tintColor: '#1F024A' }} />
                            </Pressable>
                        </View>
                    }
                </View>
                {contact.status == "NUMBER_OK" && <View style={styles.lastSeenSection}>
                    <LastSeen lastSeen={contact.lastSeen} isOnline={contact.isOnline} />
                </View>}
            </View>
            {!['WARNING', 'BAD_NUMBER'].includes(contact.status) &&
                <View style={{ width: "100%", backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.mainCardBackground.backgroundColor : 'rgb(32 193 154)' }}>

                    <View style={{ flexDirection: 'row', alignItems: "center", paddingVertical: 10, paddingHorizontal: 20, width: "100%" }}>
                        <Switch
                            onValueChange={() => changeNotifyNumber()}
                            value={contact.notify}
                            style={{ transform: [{ scale: 0.8 }], marginLeft: -8 }}
                        />
                        <Pressable style={{ flexGrow: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} onPress={() => toggleContactCollapse(contact)}>
                            <Text style={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 12, marginLeft: 8, lineHeight: 13, color: "white", fontWeight: "600" }}>{t('main.contactCard.onlineNotification')}</Text>
                            <View style={{ transform: [{ rotate: !contact.isCollapsed ? '180deg' : '0deg' }] }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 21.5625C13.8913 21.5625 15.7401 21.0017 17.3126 19.9509C18.8852 18.9002 20.1108 17.4067 20.8346 15.6594C21.5584 13.9121 21.7477 11.9894 21.3788 10.1344C21.0098 8.27951 20.0991 6.57563 18.7617 5.23829C17.4244 3.90095 15.7205 2.99021 13.8656 2.62124C12.0106 2.25227 10.0879 2.44164 8.34059 3.1654C6.59328 3.88917 5.09982 5.11482 4.04908 6.68736C2.99834 8.25991 2.4375 10.1087 2.4375 12C2.44048 14.5352 3.44891 16.9657 5.24159 18.7584C7.03426 20.5511 9.46478 21.5595 12 21.5625ZM12 3.5625C13.6688 3.5625 15.3001 4.05735 16.6876 4.98448C18.0752 5.9116 19.1566 7.22936 19.7952 8.77111C20.4339 10.3129 20.6009 12.0094 20.2754 13.6461C19.9498 15.2828 19.1462 16.7862 17.9662 17.9662C16.7862 19.1462 15.2828 19.9498 13.6461 20.2754C12.0094 20.6009 10.3129 20.4338 8.77111 19.7952C7.22936 19.1566 5.9116 18.0752 4.98448 16.6876C4.05736 15.3001 3.5625 13.6688 3.5625 12C3.56499 9.763 4.45473 7.61833 6.03653 6.03653C7.61833 4.45473 9.763 3.56498 12 3.5625ZM8.6025 13.1475C8.54724 13.096 8.50291 13.0339 8.47217 12.9649C8.44142 12.8959 8.42489 12.8214 8.42356 12.7459C8.42223 12.6704 8.43612 12.5953 8.46441 12.5253C8.4927 12.4553 8.53481 12.3916 8.58823 12.3382C8.64164 12.2848 8.70527 12.2427 8.77531 12.2144C8.84535 12.1861 8.92037 12.1722 8.9959 12.1736C9.07142 12.1749 9.14591 12.1914 9.21491 12.2222C9.28391 12.2529 9.34601 12.2972 9.3975 12.3525L11.4375 14.3916L11.4375 8.25C11.4375 8.10082 11.4968 7.95774 11.6023 7.85225C11.7077 7.74676 11.8508 7.6875 12 7.6875C12.1492 7.6875 12.2923 7.74676 12.3978 7.85225C12.5032 7.95774 12.5625 8.10082 12.5625 8.25L12.5625 14.3916L14.6025 12.3525C14.7091 12.2531 14.8502 12.199 14.9959 12.2016C15.1416 12.2042 15.2807 12.2632 15.3837 12.3663C15.4868 12.4693 15.5458 12.6084 15.5484 12.7541C15.551 12.8998 15.4969 13.0409 15.3975 13.1475L12.3975 16.1475C12.292 16.2528 12.1491 16.312 12 16.312C11.8509 16.312 11.708 16.2528 11.6025 16.1475L8.6025 13.1475Z" fill="white" />
                                </svg>
                            </View>
                        </Pressable>
                    </View>

                    <ViewSlide show={!contact.isCollapsed} styles={{ width: "100%", backgroundColor: "white" }}>
                        <View style={{ width: '100%', flexDirection: 'row', flexGrow: 1, flex: 1, paddingTop: 16, paddingBottom: 18, paddingHorizontal: 12, paddingRight: 0 }} >
                            <TouchableWithoutFeedback onPress={() => navigation.dispatch(StackActions.push('OnlineHistory', { contact: contact.phone }))}>
                                <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center', display: contact.status != 'WARNING' ? 'flex' : 'none' }} >
                                    <ImageAspectFit asset={require('../../../assets/images/material-symbols_history.png')} style={{ width: 30, height: 30 }} imageStyle={{ tintColor: /*'#e99b2d'*/ '#1F024A' }} />
                                    <Text style={styles.iconText}>{t('main.contactCard.onlineHistory')}</Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableWithoutFeedback onPress={() => navigation.dispatch(StackActions.push('CompareWith', { contact: contact.phone }))}>
                                <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center', display: contact.status != 'WARNING' ? 'flex' : 'none' }} >
                                    <ImageAspectFit asset={require('../../../assets/images/chat-alt-4.png')} style={{ width: 30, height: 30 }} imageStyle={{ tintColor: '#1F024A' }} />
                                    <Text style={styles.iconText}>{t('main.contactCard.chatODDS')}</Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableWithoutFeedback onPress={() => navigation.dispatch(StackActions.push('Nightwatch', { contact: contact.phone }))}>
                                <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center', display: contact.status != 'WARNING' ? 'flex' : 'none' }} >
                                    <ImageAspectFit asset={require('../../../assets/images/nighwatch.png')} style={{ width: 28, height: 28 }} imageStyle={{ tintColor: '#1F024A' }} />
                                    <Text style={styles.iconText}>{t('main.contactCard.nightWatch')}</Text>
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableWithoutFeedback onPress={() => removeContact(contact)}>
                                <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }} >
                                    <ImageAspectFit asset={require('../../../assets/images/ant-design_delete-outlined.png')} style={{ width: 30, height: 30 }} imageStyle={{ tintColor: '#1F024A' }} />
                                    <Text style={styles.iconText}>{t('main.contactCard.delete')}</Text>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>
                    </ViewSlide>
                </View>
            }
        </Animated.View>
    )
}


const styles = StyleSheet.create({
    containerCenter: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    main: {
        flexGrow: 1,
        alignItems: 'center',
        padding: 24,
        paddingTop: 0,
        maxWidth: 600,
        alignSelf: 'center'
    },
    userInfoSection: {
        flexGrow: 1,
        flexDirection: 'column',
        marginLeft: 14
    },
    userInfoName: {
        ...globalStyles.fontFamily,
        fontSize: 17,
        fontWeight: '600',
        color: 'white'
    },
    userInfoSim: {
        ...globalStyles.fontFamily,
        fontSize: 15,
        fontWeight: "500",
        color: 'white'
    },
    showConnectionManagerButton: {
        ...globalStyles.fontFamily,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 15,
        width: "100%",
        height: 46,
        alignSelf: "center"
    },
    connectionManagerButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(38,50,56,0.3)',
        borderRadius: 20,
        width: '100%',
        height: 40,
        paddingTop: 0
    },
    connectionManagerText: {
        fontSize: 14,
        ...globalStyles.fontFamily,
    },
    footer: {
        height: 25
    },
    body: {
        ...globalStyles.fontFamily,
        fontSize: 19,
    },
    listItem: {
        marginTop: 12,
        textAlign: 'center'
    },
    contactInfoSection: {
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
        marginLeft: 14,
        paddingVertical: 0,
        justifyContent: 'center'
    },
    contactInfoName: {
        ...globalStyles.fontFamily,
        fontSize: 17,
        fontWeight: 'bold',
    },
    contactInfoNumber: {
        ...globalStyles.fontFamily,
        fontSize: 15,
        opacity: 0.8
    },
    divider: {
        width: '100%',
        height: 1,
        backgroundColor: 'lightgray',
        marginTop: 10,
        marginBottom: 16
    },
    iconText: {
        ...globalStyles.fontFamily,
        fontSize: 10,
        textAlign: 'center',
        lineHeight: 13,
        marginTop: 9,
        color: "#1F024A"
    },
    lastSeenSection: {
        justifyContent: "flex-start",
        alignSelf: "stretch"
    }
});