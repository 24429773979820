import React, { Component, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AppState, DeviceEventEmitter, EmitterSubscription, NativeEventSubscription, ScrollView, StyleSheet, Text, TouchableOpacity, View, Animated, Image } from 'react-native';
import { HeaderComponent } from '../components/header';
import { useAuthService } from '../services/auth-service';
import { configService } from '../services/config.service';
import { socketService } from '../services/socketio';
import { http, isApp } from '../shared';
import { globalStyles } from '../shared/styles';
import { store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';
import { useNavigation, StackActions, useFocusEffect } from "@react-navigation/native";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { clearAllAuth, setIsWALoggedIn, setTempLoginCode } from '../store/auth/auth.actions';
import { SliderIndicator } from '../components/qr-scan/slider-indicator';
import ReactCodeInput from 'react-code-input';
import { useRouter } from '../services/router-service';
import { Trans, useTranslation } from 'react-i18next';
import { APP, CURRENT_APP } from '../shared/constants';

declare var window: any

let _listener: EmitterSubscription
let loggingInInterval: any
declare var location: any

enum STEP {
  'ENTER_CODE' = 'ENTER_CODE',
  'SCAN_QR' = 'SCAN_QR',
  'CONNECTING' = 'CONNECTING',
  'DONE' = 'DONE',
  'ANOTHER_DEVICE' = 'ANOTHER_DEVICE',
  'ERROR_BACKEND' = 'ERROR_BACKEND'
}

export const QRScanScreen = () => {

  const authService = useAuthService()
  const {t} = useTranslation()

  const windowWidth: number = window.innerWidth

  const [code, setCode] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [step, setStep] = useState<STEP>(null)
  const [qrCode, setQrCode] = useState<string>("")

  const [loadingPercent, setLoadingPercent] = useState<number>(0)
  const [connected, setConnected] = useState<boolean>(false)
  const [connectingCount, setConnectingCount] = useState<number>(0)
  const [intervalSeconds, setIntervalSeconds] = useState<number>(0)
  const [loadingMsg, setLoadingMsg] = useState<string>("")

  useEffect(() => {
    (async () => {
      setError(null)
      if (code.length == 4) {
        let result = await http.post("auth/check-login-code", { code, screen: { with: screen.width, height: screen.height } })
        console.log(result)
        if (!!result.data) result = result.data;
        if (!result?.ok) {
          if (!!result.message) setError(result.message);
          return
        }
        store.dispatch(setTempLoginCode(result.code))
        setStep(STEP.SCAN_QR)
      }
    })()
  }, [code, setError
  ])

  useEffect(() => {
    (async () => {
      if (step == STEP.SCAN_QR) {
        _listener = DeviceEventEmitter.addListener('*', data => socketEventHandler(data))
        await socketService.waitSocketReady()

        var response: any = await socketService.send('checkLogin')
        if (response.data.loggedIn === true) {
          return store.dispatch(setIsWALoggedIn(true))
        }

        socketService.send("startLogin");
      }
    })()
  }, [step])

  const socketEventHandler = (data: any) => {
    switch (data.event) {
      case 'connecting':
        connecting();
        break;
      case 'connected':
        setConnected(true)
        break;
      case 'goToMain':
      case 'update-contact-list':
        //store.dispatch(setIsWALoggedIn(true))
        break;
      case 'scanQR':
        setQrCode(data.data.qr)
        break
      case 'error':
        setStep(STEP.ERROR_BACKEND);
        break;
      case 'stopQR':
        //location.reload()
        break;
      default:
    };
  };

  //LOGICA VISUAL LOGIN
  const connecting = useCallback(() => {
    setConnectingCount(connectingCount => connectingCount + 1)
  }, [setConnectingCount])

  useEffect(() => {
    if (connectingCount == 2) {
      startLoginProcess()
    }
  }, [connectingCount])

  const startLoginProcess = useCallback(() => {
    if (step == STEP.CONNECTING) return;
    setStep(STEP.CONNECTING);
    loggingInInterval = setInterval(() => {
      setIntervalSeconds(intervalSeconds => intervalSeconds + 1)
    }, 1000);
  }, [step, setStep])

  useEffect(() => {
    let message = "";
    if (intervalSeconds <= 2) message = t('qrScan.msgConnecting1');
    else if (intervalSeconds <= 5) message = t('qrScan.msgConnecting2');
    else message = t('qrScan.msgConnecting3');
    loadingBar(intervalSeconds * 5, message);

    //esperamos 15 segundos para que lleguen los contactos
    if (intervalSeconds >= 12) {
      clearInterval(loggingInInterval);
      if (connected) {//si se conecto
        loadingBar(100, t('qrScan.msgConnecting4'));
        setTimeout(() => {
          setStep(STEP.DONE)
          store.dispatch(clearAllAuth())
          _listener.remove()
        }, 1000);
      } else {//no se conecto
        setStep(STEP.ERROR_BACKEND);
      }
    };
  }, [intervalSeconds])

  const loadingBar = useCallback((pct: number, message: string | null = null) => {
    setLoadingMsg(message || "")
    setLoadingPercent(pct)
  }, [setLoadingMsg, setLoadingPercent])
  //END LOGICA VISUAL

  useEffect(() => {
    if (authService.isLoggedIn) setStep(STEP.ANOTHER_DEVICE)
    else setStep(STEP.ENTER_CODE)
  }, [authService.isLoggedIn])

  useFocusEffect( useCallback(()=>{
      if( authService.tempLoginCode )store.dispatch(setTempLoginCode(null))
    },[])
  )

  const textColor = useMemo(()=>{
    if (CURRENT_APP == APP.CHATWATCH) {
      return globalStyles.mainCardBackground.backgroundColor
  } else if (CURRENT_APP == APP.WAWATCHER) {
      return 'black'
  }
  },[])

  return (
    <View style={[globalStyles.screenBase, { backgroundColor: "white", justifyContent: "center" }]} >

      {step == STEP.ENTER_CODE && <View style={{ alignItems: "center" }}>
        <Text style={[globalStyles.introBaseTextBold, { color: textColor, fontSize: 18, marginBottom: 24 }]}>{t('qrScan.enterCode')}:</Text>
        <ReactCodeInput inputMode='numeric' name='code' type='number' inputStyle={styles.codeDigitView as any} fields={4} value={code} onChange={(code) => setCode(code)} />

        {!!error && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
          <Text style={[globalStyles.introBaseText, { color: 'rgba(255,53,53,0.8)', fontSize: 18, textAlign: "center" }]}>{error}</Text>
        </View>}
      </View>}

      {step == STEP.SCAN_QR && <View style={{ alignItems: "center" }}>
        <Image source={{ uri: qrCode }} resizeMode='contain' style={{ width: 300, maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1 }} />
        <Text style={[ globalStyles.introBaseTextBold, { color: textColor, fontSize: 18, marginTop: 24, textAlign: "center" }]}>{t('qrScan.followInstructions')}</Text>
      </View>}

      {step == STEP.CONNECTING && <View style={{ alignItems: "center" }}>
        <View style={{ width: 200 }}>
          <CircularProgressbar value={loadingPercent} text={``} styles={buildStyles({
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
            pathColor: globalStyles.mainCardBackground.backgroundColor,
            trailColor: `rgba(0,0,0,0.1`,
          })} />
        </View>
        <Text style={[ globalStyles.introBaseTextBold, { color: textColor, fontSize: 18, marginTop: 24 }]}>{loadingMsg}</Text>
      </View>}

      {step == STEP.DONE && <View style={{ alignItems: "center" }}>
        <Image source={ configService.get('logo') } resizeMode='contain' style={{ width: isApp(APP.CHATWATCH) ? 200 : 400, maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: isApp(APP.CHATWATCH) ? 1 : 4 }} />
        <Text style={[globalStyles.introBaseTextBold, { color: textColor, fontSize: 18, marginTop: 44, textAlign: "center" }]}>{t('qrScan.enjoy').replace('ChatWatch',configService.get('appDisplayName'))}</Text>
        <Text style={[globalStyles.introBaseText, { color: textColor, fontSize: 18, fontWeight: "200", marginTop: 34, textAlign: "center" }]}>{t('qrScan.canClose')}</Text>
      </View>}

      {step == STEP.ANOTHER_DEVICE && <View style={{ alignItems: "center" }}>
        <Image source={require('../../assets/images/big-alert.png')} resizeMode='contain' style={{ width: 120, maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1, filter: isApp(APP.WAWATCHER) ? 'grayscale(1) brightness(1.5)' : undefined }} />
        <Trans i18nKey="qrScan.openUrl">
          <Text style={[ globalStyles.introBaseText, { color: textColor, fontSize: 18,  marginTop: 44, textAlign: "center" }]}>You must open this url{'\n'}from <strong>ANOTHER</strong> device</Text>
        </Trans>
      </View>}

      {step == STEP.ERROR_BACKEND && <View style={{ alignItems: "center" }}>
        <Image source={require('../../assets/images/big-alert.png')} resizeMode='contain' style={{ width: 120, maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1 }} />
        <Text style={[ globalStyles.introBaseText, { color: textColor, fontSize: 18, marginTop: 44, textAlign: "center" }]}>{t('qrScan.updatingServices')}</Text>
      </View>}

    </View>
  );
}

const styles = StyleSheet.create({
  codeDigitView: {
    width: 42,
    height: 51,
    backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.mainCardBackground.backgroundColor : 'rgb(32 193 154)',
    borderRadius: 8,
    margin: "10px" as any,
    border: "none",
    fontFamily: globalStyles.introBaseText.fontFamily,
    fontSize: 26,
    color: "white",
    fontWeight: "700",
    textAlign: "center",
    paddingTop: 0
  },
  codeDigit: {
    fontFamily: globalStyles.introBaseText.fontFamily,
    fontSize: 26,
    color: "white",
    fontWeight: "700",
    transform: [{ translateY: -2 }]
  }
});