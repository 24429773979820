import React, { useCallback, useState } from 'react';
import { Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { StackActions, useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LogoAndSlogan } from '../components/auth/logo-and-slogan';
import { useAuthService } from '../services/auth-service';
import { http } from '../services/http-service';
import { useRouter } from '../services/router-service';
import { Platform as PlatformEnum } from '../shared/constants';
import { getDeviceLanguage, validateEmail } from '../shared/helpers';
import { globalStyles } from '../shared/styles';
import { setAccessToken, setSubscription } from '../store/auth/auth.actions';

export const LoginScreen = () => {

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const route = useRoute()
  const { t } = useTranslation()

  const authService = useAuthService()
  const router = useRouter()

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState<string>(null)

  const login = useCallback(async () => {
    if (!validateEmail(email)) {
      return setError(t('login.errorValidEmail'));
    }
    if (!password) {
      return setError(t('login.errorPasswordEmpty'));
    }
    setError(null)
    const payload: any = {
      email,
      password,
      language: getDeviceLanguage(),
      timezoneOffset: new Date().getTimezoneOffset(),
      platform: PlatformEnum.WEB
    };
    http.post("auth/login", payload)
      .then(response => {
        dispatch(setAccessToken(response.data.accessToken))
        dispatch(setSubscription(response.subscription))
      })
      .catch(e => setError(e))
  }, [email, password, setError])

  const goToRegister = useCallback(() => {
    navigation.navigate('Register' as never)
  }, [navigation])

  const goToForgotPasswword = useCallback(() => {
    navigation.dispatch(StackActions.push('RecoverPassword'))
  }, [navigation])

  useFocusEffect(useCallback(() => {
    router.notFunctionalGuard({ from: "Login" })
  }, [authService.isLoggedIn])
  )

  useFocusEffect(useCallback(() => {
    if ((route.params as any)?.withoutAnimation) navigation.setOptions({
      animationEnabled: false,
      cardStyleInterpolator: undefined,
    });
  }, [])
  );

  return <View style={globalStyles.screenBase} >
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, padding: 24, paddingBottom: 0, maxWidth: 600, alignSelf: "center" }} >
      <LogoAndSlogan />

      <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { marginTop: 40, textAlign: 'center', fontSize: 18, textTransform: 'uppercase' }]}>{t('login.login')}</Text>

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'email-address'}
        textContentType={'emailAddress'}
        autoCapitalize={'none'}
        autoComplete={'email'}
        autoCorrect={false}
        placeholder={t('login.email')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setEmail(text)}
        value={email}
      />

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'default'}
        textContentType={'password'}
        autoCapitalize={'none'}
        autoComplete={'password'}
        autoCorrect={false}
        secureTextEntry={true}
        placeholder={t('login.password')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setPassword(text)}
        value={password}
      />

      <Pressable onPress={goToForgotPasswword} style={{ alignSelf: "flex-end" }}>
        <Text style={[globalStyles.baseText, { fontSize: 14, opacity: 0.6, alignSelf: "flex-end", marginTop: 10 }]}>{t('login.forgotPassword')}</Text>
      </Pressable>

      {!!error && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
        <Text style={[ globalStyles.fontFamily, { color: 'rgba(255,53,53,0.8)', fontSize: 18, textAlign: "center" }]}>{error}</Text>
      </View>}

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%" }}>
      <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20 }]} onPress={login}>
        <Text style={globalStyles.primaryButtonText}>{t('login.logIn')}</Text>
      </TouchableOpacity>

      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
        <Text style={[globalStyles.baseText, { fontSize: 18, marginRight: 10, opacity: 0.5 }]}>{t('login.dontHaveAccount')}</Text>
        <TouchableOpacity onPress={() => goToRegister()}>
          <Text style={[globalStyles.baseText, { fontSize: 18, fontWeight: "700" }]}>{t('login.register')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>

}

