import { useRoute, useNavigation, useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { DatePickerModal } from 'react-native-paper-dates';
import { ContactAvatarWithStatus } from '../components/contact-avatar-with-status';
import { HeaderComponent } from '../components/header';
import { RootStackParamList } from '../navigation/root-stack-param-list';
import { http } from '../services/http-service';
import { HistoryRecord, extractPayload, parseChats } from '../shared/ChatDataParser';
import { formatDate, getTodayDateString, isApp } from '../shared/helpers';
import { globalStyles } from '../shared/styles';
import { RootState, store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';
import { connect, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import { useAuthService } from '../services/auth-service';
import { useRouter } from '../services/router-service';
import { useTranslation } from 'react-i18next';
import { APP } from '../shared/constants';

const isRowEmpty = (row: string[]) => {
  return row.every(i => i == 'offline')
}

export const OnlineHistoryScreen = () => {

  const {t} = useTranslation()
  const navigation = useNavigation()
  const route = useRoute()
  const { contact: contactPhone } = route.params as RootStackParamList['OnlineHistory'];
  const contact = useSelector((state: RootState) => state.agenda.subscribed.find(c => c.phone == contactPhone))

  const isLoading = useSelector((state: RootState) => state.ui.isLoading)

  const [date, setDate] = useState<Date>(new Date())
  const [error, setError] = useState<string>(null)
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const [history, setHistory] = useState<any[]>([])
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const router = useRouter()
  const authService = useAuthService()

  useFocusEffect(useCallback(() => {
    router.functionalGuard({from:"OnlineHistory"})
  }, [authService.isWALoggedIn,authService.isLoggedIn,authService.isValidSubscription])
  )

  const getOnlineHistory = useCallback( async() => {
    console.log("getOnlineHistory")
    if( !history ) store.dispatch(setIsLoading(true));

    let newDate = date
        if (formatDate(newDate) == getTodayDateString()) {
            console.log("is today")
            newDate = new Date()
        }else{
            console.log("is NOT today")
            newDate = new Date( newDate.getTime() + (newDate.getTimezoneOffset() * 60000) )
        }

    var data = {
      contacts: [contact],
      date: newDate.toISOString(),
      type: "history"
    };

    return http
      .post("online-period/online-history", data)
      .then((result: any) => {
        //console.log(result.data)
        const extracted = extractPayload(result.data)
        parseChats(extracted, date, contact).then((historyRecords: any) => {
          //console.log(JSON.stringify(historyRecords, null, 2))
          historyRecords.historyRecords.forEach(row => row?.contact1_online && (row.contact1_online = row.contact1_online.reverse()))
          setHistory(historyRecords.historyRecords)
          setIsUpdating( false )
        });
      })
      .catch((e: any) => {
        // ERROR
        console.log(e);
        setError(e?.error?.message || "Are you sure do you have internet?")
      })
      .finally(() => {
        store.dispatch(setIsLoading(false))
      })
  }, [date, contact, history, setHistory])


  const isEmpty = useCallback(() => {
    return !!history.length && history.length <= 2 && history.every(r => isRowEmpty(r.contact1_online))
  }, [history])

  const getDateString = useCallback(() => {
    var isToday = formatDate(date) == getTodayDateString()
    if (isToday) return t('onlineHistory.last24Hours');
    return formatDate(date)
  }, [date])

  useEffect(() => {
    getOnlineHistory()
  }, [date])

  useEffect(() => {
    if( contact?.isOnline ){
      setIsUpdating( true )
    }
    if (!contact?.isOnline && !!history) {
      setDate(new Date())
    }
  }, [contact?.isOnline])


  /*useEffect(() => {
    if (!contact || !contact.id) navigation.navigate("Main", { withoutAnimation: true })
  }, [contact])*/

  return !contact ? null : (
    <View style={globalStyles.screenBase} >
      <HeaderComponent title={t('onlineHistory.onlineHistory')} />

      <View style={styles.main}>
        <View style={[{ backgroundColor: "white", borderRadius: isApp(APP.CHATWATCH) ? 10 : 0, padding: 0, overflow: "hidden", maxHeight: "100%" }]}>

          <View style={[{ backgroundColor: globalStyles.mainCardBackground.backgroundColor, alignItems: 'center', paddingVertical: 10, width: '100%' }]}>
            <Pressable onPress={() => setDatePickerOpen(true)} >
              <Text style={[styles.subheaderText, globalStyles.fontFamily]}>{getDateString()}</Text>
            </Pressable>
            <DatePickerModal
              allowEditing={false}
              locale="en"
              mode="single"
              label={t('onlineHistory.selectADate')}
              visible={datePickerOpen}
              saveLabel=' '
              saveLabelDisabled={true}
              onChange={(params) => {
                setDatePickerOpen(false)
                setDate(params.date)
              }}
              editIcon={"fsdfds"}
              validRange={{
                endDate: new Date()
              }}
              presentationStyle='pageSheet'
              onDismiss={() => {
                setDatePickerOpen(false)
              }}
              date={date}
              onConfirm={(params) => {
                setDatePickerOpen(false)
                setDate(params.date)
              }}
            />
          </View>

          <View style={{ alignItems: 'center', paddingVertical: 10, width: '100%' }}>
            <ContactAvatarWithStatus contact={contact} />
            <View style={{ flexDirection: 'row', marginBottom: 8, marginTop: 10, alignItems: 'center' }}>
              <Text style={[styles.subheaderText, globalStyles.fontFamily, { width: 100, marginRight: 20, color: "#1F024A" }]}>{t('onlineHistory.time')}</Text>
              <Text style={[styles.subheaderText, globalStyles.fontFamily, { width: 140, color: "#1F024A" }]}>{contact?.name}</Text>
            </View>
          </View>

          {isLoading && <ActivityIndicator color={globalStyles.mainCardBackground.backgroundColor} size={30} style={{ marginBottom: 20 }} />}

          <ScrollView contentContainerStyle={{ justifyContent: "flex-start", paddingHorizontal: 30 }}>

            {!isEmpty() && <View style={{ flexDirection: 'column', alignItems: 'center' }}>

            { isUpdating && <View style={{ flexDirection: 'row', marginBottom: 6, alignItems: 'center' }} key={'row-online-realtime'}>
                  <Text style={[styles.timeSpan, { opacity: 0 }]}></Text>
                  <View style={{ width: 140, alignItems: 'center' }}>
                  <div className="dot-flashing"></div>
                  </View>
                </View> }

              {history.map((hr, i) =>
              (!hr.offlinePeriod ?
                <View style={{ flexDirection: 'row', marginBottom: 6, alignItems: 'center' }} key={'row-online-' + i}>
                  <Text style={[styles.timeSpan, { opacity: !hr.hourRange.to && !isRowEmpty(hr.contact1_online) ? 1 : 0 }]}>{hr.hourRange.from}</Text>
                  <View style={{ width: 140, alignItems: 'center' }}>
                    {(hr.contact1_online as any[]).map((cssClass, i2) =>
                      <View style={isRowEmpty(hr.contact1_online) ? globalStyles.dot : globalStyles[cssClass + 'Row']} key={'row-online-' + i + '-' + i2} />
                    )}
                  </View>
                </View>
                :
                <View style={{ width: '100%', alignItems: 'center', marginTop: 30, marginBottom: 50 }} key={'row-offline-' + i}>
                  <Text style={styles.offlinePeriodTextPrimary}>{t('onlineHistory.offlineFor')} {hr.offlinePeriod.hours} {t('onlineHistory.hour',{count:hr.offlinePeriod.hours})}</Text>
                  <Text style={styles.offlinePeriodTextSecondary}>{t('onlineHistory.from')} {hr.offlinePeriod.start.slice(0, -3)} {t('onlineHistory.to')} {hr.offlinePeriod.end.slice(0, -3)}</Text>
                </View>
              )
              )}
            </View>}

            <View style={{ padding: 20, display: isEmpty() ? 'flex' : 'none' }}>
              <Text style={[globalStyles.title, { color: "rgb(31, 2, 74)", textAlign: "center" }]}>
                {t('onlineHistory.notEnoughtData')}
              </Text>
            </View>

          </ScrollView>

        </View>
      </View>

      <View style={{ paddingHorizontal: 20, paddingBottom: 17, width: "100%", maxWidth: 600 }}>
        <Pressable style={globalStyles.primaryButton} onPress={() => setDatePickerOpen(true)}>
          <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>{t('onlineHistory.changeDate')}</Text>
        </Pressable>
        <Pressable style={[globalStyles.secondaryButton, { marginTop: 15 }]} onPress={() => navigation.goBack()}>
          <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>{t('goBack')}</Text>
        </Pressable>
      </View>

    </View>
  );

}

const styles = StyleSheet.create({
  subheaderText: {
    ...globalStyles.fontFamily,
    fontSize: 12,
    fontWeight: "700",
    textAlign: 'center',
    color: "white"
  },
  containerCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    flex: 1,
    flexGrow: 1,
    width: "100%",
    alignItems: 'stretch',
    padding: isApp(APP.CHATWATCH) ? 20 : 0,
    paddingTop: isApp(APP.CHATWATCH) ? 10 : 0,
    maxWidth: 600,
    alignSelf: "center"
  },
  contactCard: {
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    width: '100%',
    height: 'auto',
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignItems: 'center',
    marginVertical: 10,
  },
  contactInfoSection: {
    flexGrow: 1,
    height: '100%',
    flexDirection: 'column',
    marginLeft: 14,
    paddingVertical: 0,
    justifyContent: 'flex-start'
  },
  contactInfoName: {
    ...globalStyles.fontFamily,
    fontSize: 18,
    fontWeight: 'bold',
  },
  contactInfoNumber: {
    ...globalStyles.fontFamily,
    fontSize: 14,
    marginBottom: 10,
    opacity: 0.8
  },
  timeSpan: {
    ...globalStyles.fontFamily,
    fontSize: 13,
    width: 100,
    marginRight: 20
  },
  offlinePeriodTextPrimary: {
    ...globalStyles.fontFamily,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8
  },
  offlinePeriodTextSecondary: {
    ...globalStyles.fontFamily,
    textAlign: 'center',
    fontSize: 16,
  }
});