import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation, StackActions, useFocusEffect } from "@react-navigation/native";
import { globalStyles } from '../shared/styles';
import { HeaderComponent } from '../components/header';
import { useAuthService } from '../services/auth-service';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { APP, PaymentProvider } from '../shared/constants';
import { http, isApp } from '../shared';
import Icon from "react-native-vector-icons/FontAwesome5";
import AwesomeAlert from 'react-native-awesome-alerts';
import { useRouter } from '../services/router-service';
import { Trans, useTranslation } from 'react-i18next';

export const SubscriptionDetailsScreen = () => {

  const authService = useAuthService()
  const navigation = useNavigation()
  const { t } = useTranslation()

  const subscription = useSelector((state: RootState) => state.auth?.subscription)

  const [subscriptionCancelled, setSubscriptionCancelled] = useState<boolean>(false)
  const [showAlert,setShowAlert] = useState<boolean>(false)

  const router = useRouter()

  useFocusEffect(useCallback(() => {
    router.functionalGuard({from:"SubscriptionDetails"})
  }, [authService.isWALoggedIn,authService.isLoggedIn,authService.isValidSubscription])
  )

  const paymentProvider = useMemo(() => {
    if (!!subscription.stripeSubscriptionId) return PaymentProvider.STRIPE;
    else if (!!subscription.paypalId) return PaymentProvider.PAYPAL;
    else if (!!subscription.nuveiSubscriptionId) return PaymentProvider.NUVEI;
  }, [subscription])

  useEffect(() => {
    if (paymentProvider == PaymentProvider.STRIPE && subscription.stripeStatus == "canceled") setSubscriptionCancelled(true);
  }, [paymentProvider])

  const cancelSubscription = useCallback(async () => {

    //this.loading = true;

    let url: any = null;
    if (paymentProvider == PaymentProvider.STRIPE) url = "/subscription/cancelStripeSubscription";
    else if (paymentProvider == PaymentProvider.NUVEI) url = "/subscription/cancelNuveiSubscription";
    else return;

    try {
      let result = await http.get(url);
      console.log(result);
      if (result.code == 0) {
        authService.getSessionInfo()
      };
    } catch (e) {
      console.log("ERROR", e)
    }
  }, [])

  useEffect(() => {
    if (!authService.isValidSubscription) {
      navigation.navigate("BuySubscription" as never)
    }
  }, [authService])

  return (
    <View style={globalStyles.screenBase} >
      <HeaderComponent title={t('subscriptionDetails.subscriptionDetails')} />

      <View style={styles.main}>
        <View style={[{ backgroundColor: "white", borderRadius: isApp(APP.CHATWATCH) ? 10 : 0, padding: 0, overflow: "hidden", maxHeight: "100%" }]}>
          <View style={[{ backgroundColor: globalStyles.mainCardBackground.backgroundColor, justifyContent: "center", alignItems: 'center', paddingVertical: 10, width: '100%', flexDirection: "row" }]}>
            <Icon size={14} color={'white'} name={paymentProvider == PaymentProvider.PAYPAL ? "paypal" : "credit-card"} solid />
            <Text style={[styles.subheaderText, globalStyles.fontFamily]}>{ t('subscriptionDetails.subscriptionDetails') }</Text>
          </View>

          <View style={{ alignItems: 'flex-start', paddingVertical: 20, paddingTop: 10, paddingHorizontal: 16, width: '100%' }}>

            <Text style={[ globalStyles.fontFamily, { fontSize: 16, fontWeight: "600" }]}><Icon size={14} color={'black'} name={"user"} solid style={{ marginRight: 6 }} /> { subscription.clientEmail }</Text>

            <View style={{ width: "100%", paddingVertical: 10 }}>
              <Text style={{ ...globalStyles.fontFamily, fontSize: 15 }}>• {t('subscriptionDetails.feature1',{count:subscription.maxNumbers})}</Text>
              <Text style={{ ...globalStyles.fontFamily, fontSize: 15 }}>• {t('subscriptionDetails.feature2')}</Text>
              <Text style={{ ...globalStyles.fontFamily, fontSize: 15 }}>• {t('subscriptionDetails.feature3')}</Text>
              <Text style={{ ...globalStyles.fontFamily, fontSize: 15 }}>• {t('subscriptionDetails.feature4')}</Text>
              <Text style={{ ...globalStyles.fontFamily, fontSize: 15 }}>• {t('subscriptionDetails.feature5')}</Text>
            </View>

            { subscriptionCancelled && <>

            <Trans i18nKey="subscriptionDetails.hasBeenCancelled">
              <Text style={{ ...globalStyles.fontFamily, fontSize: 16, fontStyle: "italic", marginVertical: 6 }}>This subscription has been <strong>cancelled</strong> and your card will not be charged again.</Text> 
            </Trans>
            <Text style={{ ...globalStyles.fontFamily, fontSize: 16, marginVertical: 6 }}>{t('subscriptionDetails.accessWillEndOn')} {subscription.end.slice(0,10)}</Text> 
            </>}

            { !subscriptionCancelled && <>
                <Text style={{ ...globalStyles.fontFamily, fontSize: 16, marginVertical: 6 }}><strong>{t('subscriptionDetails.nextPaymentDate')}:</strong> {subscription.end.slice(0,10)}</Text> 
              { paymentProvider == PaymentProvider.STRIPE && <Pressable style={[{ marginTop: 15, padding: 8, backgroundColor: "lightgray" }]} onPress={() => setShowAlert(true)}>
          <Text style={[globalStyles.fontFamily, { color: 'black', fontWeight: 'bold', fontSize: 13 }]}>{t('subscriptionDetails.cancelPlan')}</Text>
        </Pressable> }
              { paymentProvider == PaymentProvider.PAYPAL && <View>
                <Text style={{ ...globalStyles.fontFamily, fontSize: 16, fontStyle: "italic", marginVertical: 6, fontWeight: "700" }}>{t('subscriptionDetails.howToCancel')}</Text> 
                <Text style={{ ...globalStyles.fontFamily, fontSize: 15 }}>{t('subscriptionDetails.howToCancelText')}</Text>
              </View> }
            </>}


          </View>


        </View>
      </View>

      <View style={{ paddingHorizontal: 20, paddingBottom: 17, width: "100%", maxWidth: 600 }}>
        <Pressable style={[globalStyles.secondaryButton, { marginTop: 15 }]} onPress={() => navigation.goBack()}>
          <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>{t('goBack')}</Text>
        </Pressable>
      </View>

      <AwesomeAlert
          show={showAlert}
          showProgress={false}
          titleStyle={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 18, fontWeight: "700" }}
          messageStyle={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 15 }}
          cancelButtonStyle={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 15 }}
          confirmButtonStyle={{ fontFamily: globalStyles.fontFamily.fontFamily, fontSize: 15 }}
          title={":( " + t('subscriptionDetails.cancelSubscription')}
          message={t('subscriptionDetails.weAreSorryToEar')}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText={t('subscriptionDetails.keepSubscription')}
          confirmText={t('subscriptionDetails.cancelSubscription')}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowAlert(false)
          }}
          onConfirmPressed={() => {
            cancelSubscription()
          }}
        />

    </View>
  )

}
const styles = StyleSheet.create({
  main: {
    flex: 1,
    flexGrow: 1,
    width: "100%",
    alignItems: 'stretch',
    padding: isApp(APP.CHATWATCH) ? 20 : 0,
    paddingTop: isApp(APP.CHATWATCH) ? 10 : 0,
    maxWidth: 600,
    alignSelf: "center"
  },
  subheaderText: {
    ...globalStyles.fontFamily,
    fontSize: 14,
    fontWeight: "700",
    textAlign: 'center',
    color: "white",
    marginLeft: 10
  },
});
