export const de = {
    "app.name": "ChatWatch",
    "language": "de",
    "slogan": "Der originale Whatsapp\nOnline-Tracker",
    "goBack": "Zurückgehen",
    "menu": {
      "home": "Startseite",
      "subscription": "Abonnement",
      "logOut": "Abmelden"
    },
    "login": {
      "login": "Anmeldung",
      "logIn": "Einloggen",
      "email": "E-Mail",
      "password": "Kennwort",
      "forgotPassword": "Passwort vergessen?",
      "dontHaveAccount": "Sie haben noch kein Konto?",
      "register": "Registrieren",
      "errorValidEmail": "Bitte geben Sie eine gültige E-Mail ein",
      "errorPasswordEmpty": "Bitte geben Sie ein Passwort ein"
    },
    "register": {
      "register": "Registrieren",
      "email": "E-Mail",
      "password": "Kennwort",
      "repeatPassword": "Passwort wiederholen",
      "alreadyHaveAccount": "Sie haben bereits ein Konto?",
      "logIn": "Einloggen",
      "errorValidEmail": "Bitte geben Sie eine gültige E-Mail ein",
      "errorPasswordEmpty": "Bitte geben Sie ein Passwort ein",
      "errorPasswordShort": "Das Passwort muss mindestens 8 Zeichen lang sein.",
      "errorPasswordNotMatch": "Passwörter sind nicht gleich"
    },
    "recoverPassword": {
      "recoverPassword": "Passwort wiederherstellen",
      "errorValidEmail": "Bitte geben Sie eine gültige E-Mail ein",
      "email": "E-Mail",
      "emailSent": "E-Mail gesendet"
    },
    "buySubscription": {
      "subscription": "Abonnement",
      "week": "WOCHE",
      "month": "MONAT",
      "year": "JAHR",
      "weekly": "WÖCHENTLICH",
      "monthly": "MONATLICH",
      "yearly": "JÄHRLICH",
      "startYourTrial": "STARTEN SIE IHRE 3 TAGE KOSTENLOSE PROBE\nABONNEMENT AUSWÄHLEN",
      "selectPlan": "PLAN AUSWÄHLEN",
      "selectedPlan": "AUSGEWÄHLTER PLAN",
      "payWith": "BEZAHLEN MIT",
      payWithCard: 'ZAHLEN SIE MIT KARTE',
      "card": "Karte",
      "fullName": "Vollständiger Name",
      "subscribe": "ABONNIEREN",
      "errorFullName": "Bitte geben Sie Ihren vollständigen Namen an.",
      "errorCouldNotCreate": "Zahlung konnte nicht erstellt werden. Versuchen Sie es erneut. KODE:1"
    },
    "chatOddsIntro": {
      "chatOdds": "Chat-Quoten",
      "wantToTrackAnother": "Möchten Sie einen anderen Kontakt zu\nverfolgen, um zu sehen, ob sie vielleicht\nzusammen chatten?",
      "later": "Später",
      "yes": "Ja"
    },
    "noContacts": {
      "noContacts": "Keine Kontakte",
      "itSeems": "Es scheint, dass Sie noch nicht\nverfolgte Kontakte haben...",
      "willBeRecorded": "Online-Informationen werden ab jetzt\nfür die ausgewählten Kontakte aufgezeichnet.\nWählen Sie den ersten zu verfolgenden Kontakt.",
      "addContact": "Kontakt zur Verfolgung hinzufügen"
    },
    "qrScan": {
      "enterCode": "Anmeldecode eingeben",
      "followInstructions": "Befolgen Sie die Anweisungen zum Scannen dieses\nQR-Code auf Ihrem Hauptgerät zu scannen.",
      "enjoy": "Viel Spaß mit ChatWatch!",
      "canClose": "Du kannst dieses Fenster schließen",
      "openUrl": "<0>Sie müssen diese Url\nvon einem <3>anderen</3> Gerät aus öffnen</0>",
      "updatingServices": "Wir aktualisieren unsere Dienste,\nbitte laden Sie die Seite neu und versuchen Sie es erneut.",
      "msgConnecting1": "Verbinden mit WA...Bitte warten...",
      "msgConnecting2": "Konto synchronisieren. Bitte warten...",
      "msgConnecting3": "Informationen abrufen... Bitte warten...",
      "msgConnecting4": "Sitzung initialisiert."
    },
    "resetPassword": {
      "resetPassword": "Passwort zurücksetzen",
      "errorPasswordEmpty": "Bitte geben Sie ein Passwort ein",
      "errorPasswordShort": "Das Passwort muss mindestens 8 Zeichen lang sein",
      "errorPasswordNotMatch": "Passwörter sind nicht gleich",
      "errorLinkNotValid": "Link nicht gültig",
      "newPassword": "Neues Passwort",
      "repeatNewPassword": "Wiederholung des neuen Passworts",
      "passwordReseted": "Passwort zurückgesetzt"
    },
    "thankYou": {
      "thankYou": "Vielen Dank\nfür Ihr Abonnement!",
      "continue": "WEITER"
    },
    "waLogin": {
      "privacyNotice": "Hinweis zum Datenschutz",
      "weNotStore": "Wir speichern, lesen oder\ngreifen auf keine WA-Kontodaten\nzu.",
      "next": "Nächste",
      "howToLogin": "Wie melde ich mich an?",
      "howToLogin1": "Suchen Sie einen Computer, Tablet, Telefon oder Smart TV\nBesuchen Sie",
      "howToLogin2": "Geben Sie folgenden Code ein",
      "link": "WA-Konto verknüpfen",
      "link1": "Öffnen Sie die WA-Einstellungen, indem Sie auf dieses Symbol tippen.",
      "link2": "Wählen Sie verknüpfte Geräte.",
      "link3": "Wenn die Kamera aktiviert ist, zeigen Sie auf Ihr\nTelefon, um den QR-Code zu scannen",
      "connecting": "VERBINDEN",
      "somethingWrong": "Es ist etwas schief gelaufen.\nBitte laden Sie diese Seite neu und versuchen Sie es erneut.",
      "msgConnecting1": "Verbinden mit WA...Bitte warten...",
      "msgConnecting2": "Konto wird synchronisiert. Bitte warten Sie...",
      "msgConnecting3": "Informationen abrufen...Bitte warten...",
      "msgConnecting4": "Sitzung initialisiert."
    },
    "main": {
      "connectionManager": "Verbindungsmanager",
      "trackedContacts": "Verfolgte Kontakte",
      "contactTrackingActive": "<0>Kontaktverfolgung ist <1>aktiv</1>.<br/>Sie können die Nachverfolgung deaktivieren, um offline angezeigt zu werden. Später können Sie es mit einem Klick aktivieren. Es ist nicht erforderlich, den QR-Code erneut zu scannen.</0>",
      "contactTrackingInactive": "<0>Kontaktverfolgung ist <1>inaktiv</1>.</0>",
      "deactivateTracking": "Tracking deaktivieren",
      "addContact": "Kontakt zur Verfolgung hinzufügen",
      "activateTracking": "Tracking aktivieren!",
      "contactCard": {
        "notTracking": "Kein Tracking",
        "howToTrack": "Wie kann ich Kontakte verfolgen?",
        "onlineNotification": "Online-Benachrichtigung",
        "onlineHistory": "Online-Geschichte",
        "chatODDS": "Chatten Sie mit OODS",
        "nightWatch": "Nachtwache",
        "delete": "Löschen",
        "lastSeen": {
          "online": "Online",
          "secsAgo": "Vor {{count}} Sek",
          "secsAgo_plural": "Vor {{count}} Sekunden",
          "minsAgo": "Vor {{count}} Min",
          "minsAgo_many": "Vor {{count}} Minuten",
          "hoursAgo": "Vor {{count}} Stunde",
          "hoursAgo_plural": "Vor {{count}} Stunden",
          "daysAgo": "Vor {{count}} Tag",
          "daysAgo_plural": "Vor {{count}} Tagen"
        }
      }
    },
    "subscriptionDetails": {
      "subscriptionDetails": "Abonnementdetails",
      "feature1": "Verfolgen Sie bis zu {{count}} Kontakte!",
      "feature2": "Online-Geschichte",
      "feature3": "Kontakt-Einblicke",
      "feature4": "KI-trainierte Chat-Wahrscheinlichkeit",
      "feature5": "Support-Team (E-Mail)",
      "hasBeenCancelled": "<0>Dieses Abonnement wurde <1>gekündigt</1> und Ihre Karte wird nicht mehr belastet.</0>",
      "accessWillEndOn": "Ihr Zugang endet am",
      "nextPaymentDate": "Nächstes Zahlungsdatum",
      "cancelPlan": "Plan kündigen",
      "howToCancel": "Wie können Sie Ihr Abonnement kündigen?",
      "howToCancelText": "Melden Sie sich bei Ihrem PayPal-Konto an.\nKlicken Sie auf Werkzeuge und wählen Sie dann oben auf der Seite Alle Werkzeuge.\nWählen Sie dann auf der Seite \"Tools\" die Option \"Wiederkehrende Zahlungen\".\nDort können Sie Ihr Abonnement kündigen.",
      "cancelSubscription": "Abonnement kündigen",
      "weAreSorryToEar": "Wir bedauern, dass Sie Ihr Abonnement kündigen möchten. Gibt es irgendwelche Probleme mit dem Service oder der Qualität?\nMöchten Sie uns eine E-Mail schicken, um mögliche Lösungen zu finden?\nWenn nicht, klicken Sie auf die Schaltfläche Abonnement kündigen.",
      "keepSubscription": "Mein Abonnement behalten"
    },
    "addNumber": {
      "selectContactToCompare": "Wählen Sie einen Kontakt\nzum Vergleichen",
      "selectContactToTrack": "Wählen Sie einen Kontakt\nzum Verfolgen",
      "searchBy": "Suche nach Telefon oder Name",
      "noResultsFor": "Keine Ergebnisse für",
      "trySearchingByPhone": "Versuchen Sie die Suche über die Telefonnummer"
    },
    "onlineHistory": {
      "onlineHistory": "Online-Verlauf",
      "last24Hours": "Letzte 24 Stunden",
      "selectADate": "Wählen Sie ein Datum",
      "time": "Zeit",
      "offlineFor": "Offline seit",
      "hour": "Stunde",
      "hour_plural": "Stunden",
      "from": "von",
      "to": "bis",
      "notEnoughtData": "Nicht genug Daten, bitte kommen Sie später wieder",
      "changeDate": "DATUM ÄNDERN"
    },
    "compareWith": {
      "selectContactToCompareWith": "Wählen Sie einen Kontakt zum Vergleich mit {n}",
      "searchBy": "Suche nach Telefon oder Name"
    },
    "chatProbability": {
      "chatProbability": "Chat-Wahrscheinlichkeit",
      "last24Hours": "Letzte 24 Stunden",
      "selectADate": "Wählen Sie ein Datum",
      "time": "Zeit",
      "score": "Ergebnis",
      "offlineFor": "Offline seit",
      "hour": "Stunde",
      "hour_plural": "Stunden",
      "from": "von",
      "to": "bis",
      "notEnoughtData": "Nicht genug Daten, bitte kommen Sie später wieder",
      "changeDate": "DATUM ÄNDERN"
    },

    nightWatch: {
      nightWatch: 'Nachtwache',
      sleptFor: 'Habe höchstens {{count}} STUNDEN GESCHLAFEN',
      sleptFor_plural: 'Habe oben {{count}} STUNDEN GESCHLAFEN',
      owlAlert: 'Eulen-Alarm',
      highestChatProbability: 'Höchste Chat-Wahrscheinlichkeit',
      minutes: 'Minute',
      minutes_plural: 'Protokoll'
    }
  }

var trad = `
Gece Gözcüsü
EN FAZLA {{count}} SAAT UYKU
EN FAZLA {{count}} SAAT UYKU
Baykuş Uyarısı
En Yüksek Sohbet Olasılığı
dakika
dakika
`

let lines = trad.split('\n').filter(e=>!!e)

console.log(`
nightWatch: {
  nightWatch: '${lines[0]}',
  sleptFor: '${lines[1]}',
  sleptFor_plural: '${lines[2]}',
  owlAlert: '${lines[3]}',
  highestChatProbability: '${lines[4]}',
  minutes: '${lines[5]}',
  minutes_plural: '${lines[6]}'
}
`)