import React, { useCallback, useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation, StackActions, useFocusEffect } from "@react-navigation/native";
import { globalStyles } from '../shared/styles';
import { HeaderComponent } from '../components/header';
import { useAuthService } from '../services/auth-service';
import { useRouter } from '../services/router-service';
import { useTranslation } from 'react-i18next';
import { isApp } from '../shared';
import { APP } from '../shared/constants';

export const ChatOddsIntroScreen = () => {

  const navigation = useNavigation()
  const router = useRouter()
  const authService = useAuthService()
  const {t} = useTranslation()

  useFocusEffect(useCallback(() => {
    router.functionalGuard({from:"ChatOddsIntro"})
  }, [authService.isWALoggedIn,authService.isLoggedIn,authService.isValidSubscription])
  )

  const goToMain = useCallback(() => {
    navigation.navigate('Main' as never)
  }, [navigation])

  const goToAddNumber = useCallback(() => {
    navigation.dispatch(
      StackActions.push('AddNumber')
    )
  }, [navigation])

  return <View style={globalStyles.screenBase} >
    <HeaderComponent title={t('chatOddsIntro.chatOdds')} canGoBack={false} />
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, paddingHorizontal: 24, maxWidth: 600, alignSelf: "center" }} >
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Image source={ require('../../assets/images/chat-odds-intro.png') } resizeMode='contain' style={{ width: "90%", maxWidth: 240, aspectRatio: 0.7, marginBottom: 34, filter: isApp(APP.CHATWATCH) ? undefined : 'hue-rotate(156deg) brightness(1.03)' }} />
        <Text style={[ globalStyles.introBaseTextBold, { fontSize: 16, textAlign: "center", lineHeight: 20, opacity: 0.6 }]}>{t('chatOddsIntro.wantToTrackAnother')}</Text>
      </View>

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%", flexDirection: "row" }}>
      <TouchableOpacity style={[globalStyles.secondaryButton, { flex: 1 }]} onPress={goToMain}>
        <Text style={globalStyles.secondaryButtonText}>{t('chatOddsIntro.later')}</Text>
      </TouchableOpacity>
      <View style={{ width: 30 }} />
      <TouchableOpacity style={[globalStyles.primaryButton, { flex: 1 }]} onPress={goToAddNumber}>
        <Text style={globalStyles.primaryButtonText}>{t('chatOddsIntro.yes')}</Text>
      </TouchableOpacity>
    </View>
  </View>

}