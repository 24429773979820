import { NavigationContainer as _NavigationContainer, useNavigationContainerRef, DefaultTheme } from '@react-navigation/native';
import { linking } from './linking';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RootState, store as _store } from '../store';
import { setCurrentScreen, setNavigationState } from '../store/navigation/navigation.actions';
import { StackNavigator } from './stack-navigator';
import { DrawerComponent } from '../components/drawer';
import { LoadingComponent } from '../components/loading';
import { LocalNotificationComponent } from '../components/local-notification';
import { AppState } from 'react-native';
import { setAppState, setIsFirstScreen } from '../store/ui/ui.actions';
import { AuthService, useAuthService } from '../services/auth-service';

export const NavigationContainer = () => {

    const store = useStore()

    const navigationRef = useNavigationContainerRef();
    const routeNameRef = useRef<string>();

    const socketIOConnected = useSelector((state:RootState)=>state.ui.socketIOConnected)

    const randomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    const getInitialState = useCallback(()=>{
      console.log("getInitialState")
      let navigationState = _store.getState().navigation.state;
      console.log("navigationState",navigationState)
        if( !Object.keys(navigationState).length ) return undefined;
        let lastRoute = navigationState.routes[navigationState.index]
        let lastRouteName = lastRoute.name
        console.log("lastRouteName",lastRouteName)
        let currentPath = window.location.pathname
        console.log("currentPath",currentPath)
        let index = Object.values( linking.config.screens ).indexOf( currentPath.slice(1) )
        console.log("index",index)
        if( index == -1 ) return navigationState;
        let currentRouteName = Object.keys( linking.config.screens )[index]
        console.log("currentRouteName",currentRouteName)
        if( currentRouteName != lastRouteName ){
            let newNavigationState = JSON.parse( JSON.stringify( navigationState ) )
            let newScreen = {
              key: currentRouteName+'-'+randomString(21),
              name: currentRouteName
          }
            console.log("PUSH to navigation state:",newScreen);
            (newNavigationState.routes as any[]).splice( navigationState.index+1, newNavigationState.routes.length, newScreen )
            store.dispatch( setNavigationState( newNavigationState ) )
            return newNavigationState
        }
        console.log("no push",navigationState)
        return navigationState
    },[])

    useEffect(()=>{
      AppState.addEventListener('change', async nextAppState =>{
        console.log("DISPACHING APP STATE CHANGE ["+nextAppState+"]...")
          store.dispatch(setAppState(nextAppState))
        })
    },[])
    
    return <_NavigationContainer
    linking={linking}
    theme={DefaultTheme}
    ref={navigationRef}
    initialState={ getInitialState() }
    onReady={() => {
      routeNameRef.current = navigationRef.getCurrentRoute().name;
      store.dispatch(setCurrentScreen(routeNameRef.current));
      store.dispatch(setNavigationState({}))
      //console.log("navigationState",navigationState)
    }}
    onStateChange={async (state) => {
      console.log("onStateChange",state)
      store.dispatch( setIsFirstScreen( false ) )
      store.dispatch( setNavigationState( state ) )
      const previousRouteName = routeNameRef.current;
      const currentRouteName = navigationRef.getCurrentRoute().name;
      if (previousRouteName !== currentRouteName) {
        routeNameRef.current = currentRouteName;
        store.dispatch(setCurrentScreen(currentRouteName));
      }
    }}
    >
    <StackNavigator />
    <DrawerComponent />
    <LoadingComponent />
    <LocalNotificationComponent />
  </_NavigationContainer>
}