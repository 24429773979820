import { useEffect, useMemo } from 'react';
import { getDeviceLanguage, getDeviceUuid, helpers } from '../shared';
import { Platform, RequestStatus } from '../shared/constants';
import { RootState, store } from '../store';
import { Subscription } from '../store/auth/AuthState';
import { clearAllAuth, setAccessToken, setAuthError, setIsWALoggedIn, setOnlineStatus, setSubscription, setUserInfo } from '../store/auth/auth.actions';
import { http } from './http-service';
import { useNavigation, StackActions } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { resetAgenda } from '../store/agenda/agenda.actions';
import { setTogglingOnlineStatus } from '../store/ui/ui.actions';


export const useAuthService = () => {

  const navigation = useNavigation()
  const authState = useSelector( (state: RootState) => state.auth )

  const isLoggedIn = useMemo(()=>!!authState.accessToken,[authState])
  const isWALoggedIn = useMemo(()=>authState.isWALoggedIn,[authState])
  const isValidSubscription = useMemo(()=>!!authState.subscription?.valid,[authState])
  const authToken = useMemo(()=>authState.accessToken,[authState])
  const tempLoginCode = useMemo(()=>authState.tempLoginCode,[authState])

  async function toggleOnlineStatus() {
    try {
      store.dispatch(setTogglingOnlineStatus(true))
      let result = await http.get('user/toggleStatus')
      store.dispatch(setOnlineStatus(result.data.responseData.status))
    } catch (e) {
      console.error(e)
    }
    store.dispatch(setTogglingOnlineStatus(false))
  }

  const getSessionInfo = async () => AuthService.getSessionInfo()

  useEffect(()=>{
    if( !isWALoggedIn ){
      store.dispatch( resetAgenda() )
    }
  },[isWALoggedIn])

  const logout = ()=>{
    store.dispatch( resetAgenda() )
    store.dispatch( clearAllAuth() )
    navigation.dispatch(
      StackActions.push("Login")
    )
  }

  return {
    isLoggedIn,
    isWALoggedIn,
    authToken,
    tempLoginCode,
    getSessionInfo,
    isValidSubscription,
    toggleOnlineStatus,
    logout
  }
}

export const AuthService = {
  isLoggedIn: () => !!store.getState().auth.accessToken,
  authToken: () => store.getState().auth.accessToken,
  tempLoginCode: () => store.getState().auth.tempLoginCode,
  getSessionInfo: async () => {
    const result = { success: false, message: null, isWALoggedIn: null, onlineStatus: null };
    if (!AuthService.isLoggedIn) {
      result.message = 'You are not logged in'
      return result
    }

    try { 
      let response = await http.get('user/getSessionInfo')
      const subscription: Subscription = response.subscription;

      store.dispatch(setSubscription(subscription))
      store.dispatch(setOnlineStatus(response.data.onlineStatus))
      store.dispatch(setIsWALoggedIn(response.data.isWALoggedIn))
      store.dispatch(setUserInfo(response.data.userInfo))

      result.success = true
      result.isWALoggedIn = response.data.isWALoggedIn
      result.onlineStatus = response.data.onlineStatus

      return result
    } catch (e) {
      console.error(e)
      result.success = false
      result.message = "ERROR"
      return result
    }
  }
}