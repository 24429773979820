import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { NavigationContainer } from './navigation/container';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import moment from "moment"
import { getDeviceLanguage } from './shared/helpers';

require("./import-fonts")
require('./services/i18n.service')
moment.locale(getDeviceLanguage())

export function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <SafeAreaProvider>
          <NavigationContainer />
      </SafeAreaProvider>
    </PersistGate>
    </Provider >
  );
}

serviceWorkerRegistration.register();