import {StyleSheet} from 'react-native';
import { APP, CURRENT_APP } from './constants';

const stylesByApp = {

  [APP.CHATWATCH]: StyleSheet.create({
    // MOCKUPS
    screenBase:{},
    //
    baseText: {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: "white"
    },
    introBaseText: {
      fontFamily: 'Raleway',
      fontSize: 15,
      color: "white",
      fontWeight: "700"
    },
    introBaseTextBold: {
      fontFamily: 'Raleway',
      fontSize: 16,
      color: "white",
      fontWeight: "700"
    },
    fontFamily: {
      fontFamily: 'Poppins'
    },
    fontFamilyBold: {
      fontFamily: 'Poppins',
      fontWeight: '700',
    },
    background: {
      backgroundColor: "#1F024A"
    },
    input: {
      width: "100%",
      height: 50,
      fontSize: 18,
      paddingHorizontal: 16,
      borderRadius: 7,
      backgroundColor: 'rgba(255,255,255,0.1)',
      backdropFilter: "blur(5px)",
      color: 'white'
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: 22,
      fontWeight: 'bold',
      color: "white"
    },
    headerTitle: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: "center",
      color: "white"
    },
    drawer: {
      position: 'absolute',
      right: -260,
      backgroundColor: "rgba(0,0,0,0.8)",
      backdropFilter: "blur(5px)",
      width: 260,
      height: '100%',
      alignItems: "center",
      padding: 20
    },
    drawerItemText: {
      fontFamily: 'Poppins',
      color: "white",
      fontSize: 16,
      paddingVertical: 16,
      opacity: 0.6,
      fontWeight: "400"
    },
    drawerItemActiveText: {
      opacity: 1,
      fontWeight: '700',
    },
    alwaysred: {
      backgroundColor: 'red',
      height: 100,
      width: 100,
    },
    alertBackground: {
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 14,
      marginVertical: 30,
      borderWidth: 1,
      borderRadius: 8,
    },
    alertSuccessBackground: {
      backgroundColor: '#dbf2e3',
      borderColor: '#c9ecd5'
    },
    alertWarningBackground: {
      backgroundColor: '#fcefdc',
      borderColor: '#fbe6ca'
    },
    alertText: {
      fontFamily: 'Poppins',
      fontSize: 17,
      textAlign: 'center'
    },
    alertSuccessText: {
      color: '#2d7345'
    },
    alertWarningText: {
      color: '#90682f'
    },
    header: {
      width: "100%",
      display: 'flex',
      flexDirection: 'row',
      height: 76,
      paddingLeft: 30,
      /*backgroundColor: '#f5f5f5',*/
      paddingHorizontal: 8
    },
    iconLeft: {
      flexGrow: 0,
      width: 50,
      height: "100%",
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginRight: 22
    },
    iconRight: {
      flexGrow: 0,
      padding: 16,
    },
    subscribeButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#007bff',
      borderRadius: 26,
      width: '100%',
      height: 44,
      paddingTop: 0,
      marginTop: 10
    },
    searchBar: {
      backgroundColor: '#9324A7',
      paddingVertical: 12,
      padding: 10,
      flexDirection: "row",
      width: "100%",
      borderRadius: 13,
      alignItems: "center",
    },
    searchBarInput: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      marginLeft: 14,
      width: "90%",
      color: "white"
    },
    contactCard: {
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 13,
        alignItems: 'center',
        marginVertical: 9,
        borderWidth: 0,
        overflow: "hidden"
    },
    mainCardBackground: {
      backgroundColor: '#9324A7',
    },
    mainCardActiveColor: {
      color: '#4bbf73',
    },
    placeholderText: {
      color: "rgba(255,255,255,0.8)"
    },
    primaryButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignSelf: "stretch",
      alignItems: 'center',
      backgroundColor: '#007bff',
      borderRadius: 13,
      width: '100%',
      height: 52,
      paddingTop: 0,
      backgroundImage: "linear-gradient(180deg,#6210DB 0%,#9324A7 100%)",
      boxShadow: "0px 4px 30px 0px rgba(0, 48, 120, 0.10)"
    },
    primaryButtonText: {
      fontFamily: 'Poppins',
      fontSize: 17,
      color: "white",
      fontWeight: 'bold',
      textTransform: "uppercase"
    },
    secondaryButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderRadius: 13,
      width: '100%',
      height: 52,
      paddingTop: 0,
      borderWidth: 1,
      borderColor: "#8D4DED",
      backgroundImage: "linear-gradient(0deg, rgba(141, 77, 237, 0.20) 0%, rgba(147, 36, 167, 0.20) 100%)",
      boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
    },
    secondaryButtonText: {
      fontFamily: 'Poppins',
      fontSize: 17,
      color: "white",
      fontWeight: 'bold',
      textTransform: "uppercase"
    },
    onlineRow: {
      width: '100%',
      height: 12,
      borderRadius: 6,
      marginBottom: 6,
      backgroundColor: '#C183D9',
    },
    offlineRow: {
      width: '100%',
      height: 12,
      borderRadius: 6,
      marginBottom: 6,
      backgroundColor: '#F4E9F6'
    },
    intersectionRow: {
      width: '100%',
      height: 12,
      borderRadius: 6,
      marginBottom: 6,
      backgroundColor: '#C183D9',
    },
    dot: {
      width: 12,
      height: 12,
      borderRadius: 6,
      marginBottom: 6,
      backgroundColor: '#F4E9F6',
    },
    scoreDot:{
      width: 12,
      height: 12,
      borderRadius: 6,
      marginBottom: 6,
      marginLeft: 3,
      backgroundColor: '#9224A6',
      opacity: 0.2
    },
    scoreDotActive: {
      opacity: 1
    }
  }),

  [APP.WAWATCHER]: StyleSheet.create({
    introBaseText: {
      fontFamily: 'Nunito Sans',
      fontSize: 15,
      color: "black"
    },
    introBaseTextBold: {
      fontFamily: 'Nunito Sans Bold',
      fontSize: 16,
      color: "black"
    },
    baseText: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      color: "black"
    },
    fontFamily: {
      fontFamily: 'Nunito Sans'
    },
    fontFamilyBold: {
      fontFamily: 'Nunito Sans Bold',
      fontWeight: '700',
    },
    background: {
      backgroundColor: "white"
    },
    input: {
      fontFamily: 'Nunito Sans',
      width: "100%",
      height: 50,
      fontSize: 18,
      paddingHorizontal: 16,
      borderRadius: 7,
      borderColor: 'rgba(0,0,0,0.2)',
      borderWidth: 2,
      backdropFilter: "blur(5px)",
      color: 'black'
    },
    title: {
      fontFamily: 'Nunito Sans',
      fontSize: 22,
      fontWeight: 'bold'
    },
    headerTitle: {
      fontFamily: 'Nunito Sans',
      fontSize: 22,
      fontWeight: 'bold',
      textAlign: "center"
    },
    drawer: {
      position: 'absolute',
      right: -260,
      backgroundColor: "white",
      width: 280,
      height: '100%',
      alignItems: "center",
      padding: 20
    },
    drawerItemText: {
      fontFamily: 'Nunito Sans',
      color: "black",
      fontSize: 16,
      paddingVertical: 16,
      opacity: 0.6,
      fontWeight: "400"
    },
    drawerItemActiveText: {
      opacity: 1,
      fontFamily: 'Nunito Sans Bold',
    },
    alwaysred: {
      backgroundColor: 'red',
      height: 100,
      width: 100,
    },
    alertBackground: {
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 14,
      marginVertical: 30,
      borderWidth: 1,
      borderRadius: 8,
    },
    alertSuccessBackground: {
      backgroundColor: '#dbf2e3',
      borderColor: '#c9ecd5'
    },
    alertWarningBackground: {
      backgroundColor: '#fcefdc',
      borderColor: '#fbe6ca'
    },
    alertText: {
      fontFamily: 'Nunito Sans',
      fontSize: 17,
      textAlign: 'center'
    },
    alertSuccessText: {
      color: '#2d7345'
    },
    alertWarningText: {
      color: '#90682f'
    },
    header: {
      width: "100%",
      display: 'flex',
      flexDirection: 'row',
      height: 100,
      backgroundColor: '#f5f5f5',
      paddingHorizontal: 8
    },
    iconLeft: {
      flexGrow: 0,
      width: 50,
      height: "100%",
      alignItems: 'center',
      justifyContent: 'center'
    },
    iconRight: {
      flexGrow: 0,
      padding: 16,
    },
    subscribeButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#007bff',
      borderRadius: 26,
      width: '100%',
      height: 44,
      paddingTop: 0,
      marginTop: 10
    },
    searchBar: {
      paddingVertical: 12,
      padding: 10,
      flexDirection: "row",
      width: "100%",
      borderRadius: 13,
      alignItems: "center",
      borderColor: "black",
      borderWidth: 2
    },
    searchBarInput: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      marginLeft: 14,
      width: "90%",
      color: "black"
    },
    contactCard: {
        flexDirection: 'column',
        backgroundColor: 'rgb(222 238 255)',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 13,
        alignItems: 'center',
        marginVertical: 9,
        borderWidth: 0,
        overflow: "hidden",
        boxShadow: '0 5px 12px rgba(0,0,0,0.14)'
    },
    mainCardBackground: { 
      backgroundColor: '#007bff',
      boxShadow: '0 5px 12px rgba(0,0,0,0.14)'
    },
    mainCardActiveColor: {
      color: '#4bbf73',
    },
    placeholderText: {
      color: "rgba(0,0,0,0.5)"
    },
    primaryButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#007bff',
      borderRadius: 6,
      width: '100%',
      height: 52,
      paddingTop: 0
    },
    primaryButtonText: {
      fontFamily: 'Nunito Sans Bold',
      fontSize: 16,
      color: "white",
      fontWeight: 'bold',
      textTransform: "uppercase"
    },
    secondaryButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#6c757d',
      borderRadius: 6,
      width: '100%',
      height: 52,
      paddingTop: 0
    },
    secondaryButtonText: {
      fontFamily: 'Nunito Sans Bold',
      fontSize: 16,
      color: "white",
      fontWeight: 'bold',
      textTransform: "uppercase"
    },
    onlineRow: {
      width: '100%',
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#2bb641',
      opacity: 0.9
    },
    offlineRow: {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#b5b5b5',
      opacity: 0.4
    },
    intersectionRow: {
      width: '100%',
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#d5eb44',
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#b5b5b5',
      opacity: 0.4
    },
    scoreDot:{
      width: 8,
      height: 8,
      borderRadius: 4,
      marginBottom: 4,
      marginLeft: 3,
      backgroundColor: '#d5eb44',
      opacity: 0.2
    },
    scoreDotActive: {
      opacity: 1
    }
  }),


  [APP.WAPATROL]: StyleSheet.create({
    fontFamily: {

    },
    background: {
      backgroundColor: "white"
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold'
    },
    headerTitle: {
      fontFamily: 'Nunito Sans',
      fontSize: 22,
      fontWeight: 'bold',
      color: 'white'
    },
    alwaysred: {
      backgroundColor: 'red',
      height: 100,
      width: 100,
    },
    alertBackground: {
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 14,
      marginVertical: 30,
      borderWidth: 1,
      borderRadius: 8,
    },
    alertSuccessBackground: {
      backgroundColor: '#dbf2e3',
      borderColor: '#c9ecd5'
    },
    alertWarningBackground: {
      backgroundColor: '#fcefdc',
      borderColor: '#fbe6ca'
    },
    alertText: {
      fontFamily: 'Nunito Sans',
      fontSize: 17,
      textAlign: 'center'
    },
    alertSuccessText: {
      color: '#2d7345'
    },
    alertWarningText: {
      color: '#90682f'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      height: 100,
      backgroundColor: '#1c3c50',
      paddingHorizontal: 8
    },
    headerIcon: {
      color: 'white',
      fontSize: 50,
      width: 0,
      height: 0
    },
    iconLeft: {
      flexGrow: 0,
      width: 50,
      height: "100%",
      alignItems: 'center',
      justifyContent: 'center'
    },
    iconRight: {
      flexGrow: 0,
      padding: 16,
    },
    subscribeButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#03c7b1',
      borderRadius: 12,
      width: '100%',
      height: 44,
      paddingTop: 0,
      marginTop: 10
    },
    mainCardBackground: {
      backgroundColor: '#1c3c50',
    },
    mainCardActiveColor: {
      color: 'white',
    },
    primaryButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#03c7b1',
      borderRadius: 26,
      width: '100%',
      height: 52,
      paddingTop: 0
    },
    secondaryButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent', 
      borderRadius: 13,
      width: '100%',
      height: 52,
      paddingTop: 0,
      borderWidth: 1,
      borderColor: "#8D4DED",
      backgroundImage: "linear-gradient(0deg, rgba(141, 77, 237, 0.20) 0%, rgba(147, 36, 167, 0.20) 100%)",
      boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
    },
    onlineRow: {
      width: '100%',
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#2bb641',
      opacity: 0.9
    },
    offlineRow: {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#b5b5b5',
      opacity: 0.4
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginBottom: 3,
      backgroundColor: '#b5b5b5',
      opacity: 0.4
    },
  }),

};


export const globalStyles = (stylesByApp[ CURRENT_APP ] as any) as typeof stylesByApp[ APP.CHATWATCH ]
globalStyles["screenBase"] = {
  width: '100%',
  height: '100vh',
  maxHeight: '100vh',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  backgroundColor: globalStyles.background.backgroundColor,
  overflowY: "scroll",
  overflowX: "hidden",
  alignItems: "center"
};