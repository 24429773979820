import React, { Component, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AppState, DeviceEventEmitter, EmitterSubscription, NativeEventSubscription, ScrollView, StyleSheet, Text, TouchableOpacity, View, Animated, Image, useWindowDimensions } from 'react-native';
import { HeaderComponent } from '../components/header';
import { useAuthService } from '../services/auth-service';
import { configService } from '../services/config.service';
import { socketService } from '../services/socketio';
import { getDeviceType, http, isApp } from '../shared';
import { globalStyles } from '../shared/styles';
import { RootState, store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';
import { useNavigation, StackActions, useFocusEffect, useRoute } from "@react-navigation/native";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { setIsWALoggedIn } from '../store/auth/auth.actions';
import { SliderIndicator } from '../components/qr-scan/slider-indicator';
import { numbersService } from '../services/numbers.service';
import { useSelector } from 'react-redux';
import { useRouter } from '../services/router-service';
import { useTranslation } from 'react-i18next';
import { APP } from '../shared/constants';

declare var window: any

let _listener: EmitterSubscription
let loggingInInterval: any
declare var location: any

enum STEP {
  'PRIVACY' = 'PRIVACY',
  'LOGIN' = 'LOGIN',
  'LINK' = 'LINK',
  'SCAN_QR' = 'SCAN_QR',
  'CONNECTING' = 'CONNECTING',
  'ERROR_BACKEND' = 'ERROR_BACKEND'
}

enum DEVICE_TYPE {
  'DESKTOP' = 'DESKTOP',
  'MOBILE' = 'MOBILE'
}

const deviceType: DEVICE_TYPE = getDeviceType() == "mobile" ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.DESKTOP

export const WALoginScreen = () => {

  const route = useRoute()
  const navigation = useNavigation()
  const authService = useAuthService()
  const { t } = useTranslation()
  const { width: windowWidth } = useWindowDimensions()

  const isWALoggedIn = useMemo(() => authService.isWALoggedIn, [authService])

  const [step, setStep] = useState<STEP>(STEP.PRIVACY)
  const [steps, setSteps] = useState<STEP[]>([])
  const [tempLoginCode, setTempLoginCode] = useState<string>(null)
  const [loadingMsg, setLoadingMsg] = useState<string>("")
  const [qrCode, setQrCode] = useState<string>("")
  const [loadingPercent, setLoadingPercent] = useState<number>(0)
  const [connected, setConnected] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [intervalSeconds, setIntervalSeconds] = useState<number>(0)
  const [slideAnim] = useState(new Animated.Value(0));

  const appState = useSelector((state: RootState) => state.ui.appState)

  const [previousAppState, setPreviousAppState] = useState<string>(appState)

  const frontUrl = useMemo(() => configService.get('codeLoginUrl'), [configService])
  const currentPage = useMemo(() => {
    return steps.indexOf(step);
  }, [step])

  const router = useRouter()

  useFocusEffect(useCallback(() => {
    router.functionalGuard({ from: "WALogin" })
  }, [authService.isLoggedIn, authService.isValidSubscription])
  )

  const nextStep = useCallback(() => {
    setStep(step => {
      let index = steps.indexOf(step)
      if (steps[index + 1] == STEP.SCAN_QR) socketService.send("startLogin");
      return steps[index + 1]
    })
  }, [setStep, steps])

  useEffect(() => {
    Animated.timing(slideAnim, {
      toValue: currentPage,
      duration: 320,
      useNativeDriver: false
    }).start();
  }, [step])

  useFocusEffect(useCallback(() => {
    if ((route.params as any)?.withoutAnimation) navigation.setOptions({
      animationEnabled: false,
      cardStyleInterpolator: undefined,
    });
  }, [])
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        slideAnim.setValue(0)
        navigation.setOptions({ animation: 'none' });
        //store.dispatch(setIsLoading(true))

        if (deviceType == DEVICE_TYPE.MOBILE) {
          setSteps([STEP.PRIVACY, STEP.LOGIN, STEP.LINK, STEP.CONNECTING])
        } else {
          setSteps([STEP.PRIVACY, STEP.SCAN_QR, STEP.CONNECTING])
        }

        _listener = DeviceEventEmitter.addListener('*', data => socketEventHandler(data))
        await socketService.waitSocketReady()

        var response: any = await socketService.send('checkLogin')
        if (response.data.loggedIn === true) {
          return store.dispatch(setIsWALoggedIn(true))
        }
        http.get('user/get-temp-login-code')
          .then(result => {
            setTempLoginCode(result.data.code)
          })
          .catch(e => {
            console.log(e)
          })
      })()

      return () => {
        store.dispatch(setIsLoading(false))
        if (_listener) _listener.remove()
        if (loggingInInterval) clearInterval(loggingInInterval)
      }
    }, [])
  );

  useFocusEffect(useCallback(() => {
    (async () => {
      if (appState == "active" && previousAppState != "active") {
        console.log("appState COME BACK TO ACTIVE!!")
        let response = await http.get('user/getSessionInfo')
        if (response.data.isWALoggedIn) {
          startLoginProcess()
          return
        }
        if (!!response.data.lastSocket) {
          console.log("response.data.lastSocket", response.data.lastSocket)
          socketEventHandler(response.data.lastSocket)
        }
      }
      setPreviousAppState(appState)
    })()
  }, [appState])
  )

  useFocusEffect(useCallback(() => {
    if (isWALoggedIn) {
      (async () => {
        console.log("_intervalSeconds", intervalSeconds)
        if (intervalSeconds < 12) {
          console.log("waiting 12 secs...")
          startLoginProcess()
          await new Promise(res => setTimeout(res, 13000))
        }
        await numbersService.getContacts()
        let subscribed = store.getState().agenda.subscribed
        if (subscribed.length) {
          navigation.dispatch(StackActions.push('Main'));
        } else {
          console.log("---> NoContacts")
          navigation.dispatch(StackActions.push('NoContacts'));
        }
      })()
    }
  }, [isWALoggedIn])
  )

  const socketEventHandler = (data: any) => {
    switch (data.event) {
      case 'opening-session':
        startLoginProcess()
        break;
      case 'connected':
        setConnected(true)
        break;
      case 'goToMain':
      case 'update-contact-list':
        setConnected(true)
        startLoginProcess()
        //store.dispatch(setIsWALoggedIn(true))
        break;
      case 'scanQR':
        setStep(step => {
          console.log("scanQR", step)
          if (step == STEP.LOGIN) return STEP.LINK;
          return step
        })
        setQrCode(data.data.qr)
        break
      case 'error':
        //setStep(STEP.ERROR_BACKEND);
        setIsError(true)
        break;
      case 'stopQR':
        //location.reload()
        break;
      default:
    };
  };

  //LOGICA VISUAL LOGIN

  const startLoginProcess = useCallback(() => {
    setStep(step => {
      if (step == STEP.CONNECTING) return step;

      loggingInInterval = setInterval(() => {
        console.log("setIntervalSeconds")
        setIntervalSeconds(intervalSeconds => intervalSeconds + 1)
      }, 1000);
      return STEP.CONNECTING;
    });
  }, [step, setStep])

  useEffect(() => {
    if (intervalSeconds == 0) return;
    let message = "";
    if (intervalSeconds <= 2) message = t('qrScan.msgConnecting1');
    else if (intervalSeconds <= 5) message = t('qrScan.msgConnecting2');
    else message = t('qrScan.msgConnecting3');
    loadingBar(intervalSeconds * 5, message);

    //esperamos 15 segundos para que lleguen los contactos
    if (intervalSeconds >= 12) {
      clearInterval(loggingInInterval);
      if (connected) {//si se conecto
        loadingBar(100, t('qrScan.msgConnecting4'));
        setTimeout(() => {
          store.dispatch(setIsWALoggedIn(true))
        }, 1000);
      } else {//no se conecto
        //setStep(STEP.ERROR_BACKEND);
        setIsError(true)
      }
    };
  }, [intervalSeconds])

  const loadingBar = useCallback((pct: number, message: string | null = null) => {
    console.log("loadingBar", pct)
    setLoadingMsg(message || "")
    setLoadingPercent(pct)
  }, [setLoadingMsg, setLoadingPercent])
  //END LOGICA VISUAL

  const getTempLoginCodeCharacter = useCallback((position: number) => {
    if (!tempLoginCode) return "";
    return String(tempLoginCode).charAt(position)
  }, [tempLoginCode])

  return (
    <View style={globalStyles.screenBase} >
      <HeaderComponent barStyle={{ position: 'absolute', zIndex: 9 }} />

      <View style={{ flex: 1.2, width: "100%", overflow: "hidden", justifyContent: "space-evenly" }}>
        <View style={{ flex: 0.1 }} />
        {!isError && <Animated.View style={{ flex: 1, flexDirection: "row", alignItems: "stretch", transform: [{ translateX: slideAnim.interpolate({ inputRange: [0, 1], outputRange: [0, -windowWidth] }) }] }}>
          <View style={{ width: windowWidth, alignItems: "center", justifyContent: "center" }} >
            <Image source={require('../../assets/images/login-slides/privacy-slide.png')} resizeMode='contain' style={{ height: "80%", maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1, filter: isApp(APP.WAWATCHER) ? 'hue-rotate(319deg) brightness(1.3)' : undefined }} />
          </View>
          {deviceType == DEVICE_TYPE.MOBILE &&
            <>
              <View style={{ width: windowWidth, alignItems: "center", justifyContent: "center" }} >
                <Image source={require('../../assets/images/login-slides/login-slide.png')} resizeMode='contain' style={{ height: "80%", maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1, filter: isApp(APP.WAWATCHER) ? 'hue-rotate(319deg) brightness(1.3)' : undefined }} />
              </View>
              <View style={{ width: windowWidth, alignItems: "center", justifyContent: "center" }} >
                <Image source={require('../../assets/images/login-slides/link-slide.png')} resizeMode='contain' style={{ height: "80%", maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1 }} />
              </View>
            </>}
          {deviceType == DEVICE_TYPE.DESKTOP &&
            <View style={{ width: windowWidth, alignItems: "center", justifyContent: "center" }} >
              <Image source={{ uri: qrCode }} resizeMode='contain' style={{ height: "80%", maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1 }} />
            </View>}
          <View style={{ width: windowWidth, alignItems: "center", justifyContent: "center" }} >
            <View style={{ width: 200 }}>
              <CircularProgressbar value={loadingPercent} text={``} styles={buildStyles({
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'round',
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
                pathColor: isApp(APP.CHATWATCH) ? globalStyles.mainCardBackground.backgroundColor : 'rgb(32 193 154)',
                trailColor: `rgba(128,128,128,0.2`,
              })} />
            </View>
          </View>
        </Animated.View>}
        {isError && <View style={{ flex: 1, flexDirection: "row", alignItems: "stretch", transform: [{ translateX: slideAnim.interpolate({ inputRange: [0, 1], outputRange: [0, -windowWidth] }) }] }}>
          <View style={{ width: windowWidth, alignItems: "center", justifyContent: "center" }} >
            <Image source={require('../../assets/images/big-alert.png')} resizeMode='contain' style={{ height: "80%", maxHeight: 560, maxWidth: windowWidth - 80, aspectRatio: 1, filter: isApp(APP.WAWATCHER) ? 'grayscale(1) brightness(1.5)' : undefined }} />
          </View>
        </View>}
      </View>

      <View style={{ flex: 0.2, alignItems: "center", justifyContent: "center" }}>
        <SliderIndicator pages={steps.length} current={currentPage} />
      </View>

      {step == STEP.PRIVACY && !isError && <View style={{ flex: 1, alignItems: "center", width: "100%", maxWidth: 600 }}>
        <Text style={[globalStyles.introBaseTextBold, { fontSize: 26, marginBottom: 16 }]}>{t('waLogin.privacyNotice')}</Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6 }]}>{t('waLogin.weNotStore')}</Text>

        <View style={{ flexGrow: 1 }} />
        <View style={{ padding: 24, paddingTop: 0, alignSelf: "center", width: "100%" }}>
          <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20 }]} onPress={() => nextStep()}>
            <Text style={[globalStyles.primaryButtonText, { fontFamily: globalStyles.introBaseTextBold.fontFamily }]}>{t('waLogin.next')}</Text>
          </TouchableOpacity>
        </View>
      </View>}
      {step == STEP.LOGIN && !isError && <View style={{ flex: 1, alignItems: "center", paddingHorizontal: 30 }}>
        <Text style={[globalStyles.introBaseTextBold, { fontSize: 26, marginBottom: 16 }]}>{t('waLogin.howToLogin')}</Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6 }]}>{t('waLogin.howToLogin1')} <Text style={{ textDecorationLine: "underline" }}>https://{frontUrl}</Text></Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", marginTop: 24, opacity: 0.6 }]}>{t('waLogin.howToLogin2')}:</Text>
        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
          <View style={styles.codeDigitView}>
            <Text style={styles.codeDigit}>{getTempLoginCodeCharacter(0)}</Text>
          </View>
          <View style={styles.codeDigitView}>
            <Text style={styles.codeDigit}>{getTempLoginCodeCharacter(1)}</Text>
          </View>
          <View style={styles.codeDigitView}>
            <Text style={styles.codeDigit}>{getTempLoginCodeCharacter(2)}</Text>
          </View>
          <View style={styles.codeDigitView}>
            <Text style={styles.codeDigit}>{getTempLoginCodeCharacter(3)}</Text>
          </View>
        </View>
      </View>}
      {(step == STEP.LINK || step == STEP.SCAN_QR) && !isError && <View style={{ flex: 1, alignItems: "center" }}>
        <Text style={[globalStyles.introBaseTextBold, { fontSize: 26, marginBottom: 16 }]}>{t('waLogin.link')}</Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6, marginTop: 4 }]}>1 - {t('waLogin.link1')}</Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6, marginTop: 4 }]}>2 - {t('waLogin.link2')}</Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6, marginTop: 4 }]}>3 - {t('waLogin.link3')}</Text>
      </View>}
      {step == STEP.CONNECTING && !isError && <View style={{ flex: 1, alignItems: "center" }}>
        <Text style={[globalStyles.introBaseTextBold, { fontSize: 26, marginBottom: 16 }]}>{t('waLogin.connecting')}...</Text>
        <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6 }]}>{loadingMsg}</Text>
      </View>}
      {isError && <View style={{ flex: 1, alignItems: "center" }}>
      <Text style={[globalStyles.introBaseTextBold, { fontSize: 26, marginBottom: 16 }]}>Ooop!</Text>
      <Text style={[globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6 }]}>{t('waLogin.somethingWrong')}</Text>
      </View>}

    </View>
  );
}

const styles = StyleSheet.create({
  codeDigitView: {
    width: 42,
    height: 51,
    backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.mainCardBackground.backgroundColor : 'rgb(32 193 154)',
    borderRadius: 8,
    marginHorizontal: 10,
    justifyContent: "center",
    alignItems: "center"
  },
  codeDigit: {
    fontFamily: "Raleway",
    fontSize: 26,
    color: "white",
    fontWeight: "700",
    transform: [{ translateY: -2 }]
  }
});
